import React, { Component } from "react";
import { ThemeProvider } from '@material-ui/core/styles';
import { Theme } from "../styles";
import LandingPage from "./LandingPage";

class EduResearchPortal extends Component{
    render() {
        return (
            <ThemeProvider theme={Theme}>
                <LandingPage/>
            </ThemeProvider>
        );
    }
}

export default EduResearchPortal;
