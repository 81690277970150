import React from 'react';
import {Route, Redirect} from 'react-router-dom';

/**
 * @return {boolean}
 */
function CheckToken() {
    // Temp Allow all the route to its default, because Authorization handles in LandingPage.js itself
    return false;
}

const PublicRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={ (props) => {

        if(CheckToken()) {
            return <Redirect to={{pathname: '/portal', state: {from: props.location}}}/>;
        }
        else {
            return <Component {...props} />;
        }
    }
    }
    />
);

export default PublicRoute;
